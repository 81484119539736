export enum AttributeNameEnum {
  NAME = 'name',
  DESCRIPTION = 'description',
  STATE = 'state',
  CONFIG = 'config',
  WORLD_AVATAR = 'world_avatar',
  WEBSITE_LINK = 'website_link',
  ACTIVE_SKYBOX = 'active_skybox',
  SKYBOX_LIST = 'skybox_list',
  SKYBOX_AI = 'skybox_ai',
  MAGIC_LINKS = 'magic_links',
  EMOJIS = 'emojis',
  EVENTS = 'events',
  VOICE_CHAT_USER = 'VoiceChatUser',
  VOICE_CHAT_ACTION = 'VoiceChatAction',
  WORLD_SETTINGS = 'world_settings',
  TELEPORT = 'teleport',
  OBJECT_COLOR = 'object_color',
  SPAWN_POINT = 'spawn_point',
  TIMELINE_LAST_SEEN = 'timeline_last_seen',
  SOUNDTRACK = 'soundtrack',
  SPATIAL_AUDIO = 'spatial_audio',
  OBJECT_EFFECT = 'object_effect',
  USER_CUSTOMISABLE_DATA = 'user_customisable_data',
  COMMENTS = 'comments',
  VOTE = 'vote',
  CANVAS = 'canvas',
  CANVAS_CONTRIBUTION = 'canvas_contribution'
}
