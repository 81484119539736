export const enGb = {
  translation: {
    staking: {
      title: 'Personal Connecting Dashboard',
      label: 'Staking',
      startLabel: '1. Start Connecting',
      walletLabel: '2. My Wallet',
      confirmLabel: '3. Authorize',
      walletTitle: 'My Wallet',
      confirmTitle: 'Authorize',
      rewardAccountError:
        'The selected destination account does not exist and cannot be used to receive rewards',
      customRewardDestination: 'Account Address',
      stopStaking: 'Stop Staking',
      withdrawUnbonded: 'Withdraw',
      unbondFunds: 'Unbond Funds',
      nominator: 'Nominator',
      validatorsTab: 'Validators',
      account: 'Account',
      authorization: 'Authorization',
      authorizeContribution: 'Authorize your contribution',
      stakingTab: {
        stakingDashboard: 'Stake KSM - Setup Nominator',
        validators: 'Stake KSM - Select Validators',
        authorization: 'Stake KSM - Authorization',
        favouriteTooltip: 'Create your list of favorite nodes for quick staking',
        selectTooltip: 'Create your diverse set of validators from below list',
        sortingInfo: {
          label: 'How we sort',
          header: 'Below list is sorted according to:',
          points: {
            1: '1) Favorite operators on top',
            2: '2) Operators in Kusamaverse',
            3: '3) Least commission',
            4: '4) Most own stake',
            5: '5) Least amount of nodes',
            6: '6) With identity',
            7: '7) The rest of nodes'
          }
        }
      },
      accountPair: 'Stash & Controller Pair',
      unbondingInfoAccounts:
        'The stash and controller pair, here the controller will be used to send the transaction.',
      unbondingPeriod:
        'The funds will only be available for withdrawal after the unbonding period, however will not be part of the staked amount after the next validator election. You can follow the unlock countdown in the UI.',
      fee: 'fee',
      bondCall: 'bond()',
      unbondCall: 'unbond()',
      withdrawUnbondedCall: 'withdrawUnbonded()',
      chillCall: 'chill()',
      chillInfo: 'Declare no desire to either validate or nominate',
      nominateCall: 'nominate()',
      setController: 'setController()',
      transactionCalls: 'Transaction Calls To Execute',
      sendingFrom: 'Sending From Account',
      walletAccount: 'Wallet Account',
      stashBalance: 'Stash Balance',
      activeStake: 'Active Stake',
      scanAccount: 'Scan Account Links',
      selectedNominees: 'Selected Nominees',
      nominate: 'nominate',
      balanceTypes: {
        account: 'Account Balance',
        transferable: 'Transferable',
        staked: 'Staked',
        unbonding: 'Unbonding',
        redeemable: 'Redeemable'
      },
      unbond: 'Unbond',
      stakingBlocks: '{{blocks}} blocks',
      erasRemaining: '{{eras}} eras remaining',
      noActiveStakes: "This Account Doesn't Have Any Active Stakes At The Moment",
      activeStakes: 'Active Stakes',
      balance: 'Balance',
      changeNominator: 'change nominator',
      stakingWalletAccountHeading: 'Staking Wallet Account',
      pickAccount: 'Pick a wallet account',
      pickDestination: 'Pick a reward destination',
      stashLabel: 'stash',
      rewardDestination: 'reward destination',
      specificDestination: 'Specific Destination',
      dockingAmount: '{{docking}} docking',
      connectionAmount: '{{connections}} connections',
      eventAmount: '{{events}} events',
      requestAirdrop: 'Get tokens',
      requestAirdropSuccess: 'Request for airdrop was successful',
      requestAirdropFailed: 'Request for airdrop failed',
      nextAirdropAvailableOn: 'Next airdrop available at {{date}}',
      processingAirdropRequest: 'Please wait 10-15 seconds for the transaction',
      unbondAmount: 'Unbond Amount',
      setAmount: 'Set Amount',
      setAmountSymbol: 'Set Amount, {{symbol}}',
      nominateAndBond: 'Nominate & Bond',
      amount: 'Amount',
      tokenAmount: 'Amount, {{amount}}',
      stakedAmount: 'Staked {{amount}}',
      back: 'Back',
      next: 'Next',
      destination: 'Destination',
      signAndSubmit: 'Sign & Submit',
      signAndConnect: 'Sign & Connect',
      unStakeContribution: 'Unstake your contribution',
      contributionMessage:
        'This account is also the destination for the rewards you receive from your contribution.',
      stakingMessage:
        'By staking Momentum ($MOM) in someones Odyssey you are showing support for their journey. Not only do you get rewards for staking, but it will also allow you to place a portal inside your Odyssey that will allow others to travel to the Odyssey you have staked in.',
      connectMessage:
        'You can freely visit any users open Odyssey. You can explore and meet them just by clicking on their Odyssey. However, to create a portal between your Odyssey and another users Odyssey, you will need to stake in them. By staking you show your support for another Odyssey.',
      connectTitle: 'Connect to another Odyssey',
      startContributing: 'Start Contributing',
      guestStakingMessage: 'Connecting and Staking are on-chain actions that require a wallet',
      guestWalletMessage: 'Here is how to create and connect your wallet to Odyssey',
      unStakeFrom: 'Unstake From',
      unStake: 'Unstake',
      errorUnstake: 'Error unstaking',
      successUnstake: 'Congratulations, you successfully unstaked {{amount}} from {{name}}',
      errorGetRewards: 'Error getting rewards',
      successGetRewards: 'Congratulations, you successfully claimed your MOM rewards!',
      getRewards: 'Get Rewards',
      error: 'Oops! Your request failed, please try again!',
      stakeSuccess: 'Congratulations, you successfully staked {{amount}} MOM in {{name}}',
      stakeSuccessTitle: 'Staking successful!',
      stakeErrorTitle: 'Staking failed!',
      unStakeSuccessTitle: 'Unstaking successful! ',
      unStakeErrorTitle: 'Unstaking failed!',
      rewardSuccessTitle: 'Rewards claim successful!',
      rewardErrorTitle: 'Rewards claim failed!',
      airdropSuccessTitle: 'Airdrop successful!',
      airdropErrorTitle: 'Airdrop failed!',
      totalRewards: 'Total rewards',
      rewards: 'Rewards',
      stashController: 'Controller',
      selectAmount: 'Select amount to stake',
      available: 'Available',
      feeInfo: 'Fees of {{fee}} will be applied for submission',
      stashIncreaseAmount: 'Stash Account (re-stake reward)',
      stashNoIncreaseAmount: 'Stash Account (do not re-stake reward)',
      controllerAccount: 'Controller Account',
      specifiedPaymentAccount: 'Specified Payment Account',
      stashAccount: 'Stash Account',
      signedSuccess: 'Transaction finished. Writing to block was successful!',
      validation: {
        unbondMinAmount: 'Minimum amount for unbonding should be a positive number',
        unbondMaxAmount:
          'Maximum amount for unbonding should not be over the bonded amount of {{bonded}}',
        controller: {
          stashed:
            'A controller account should not map to another stash. This selected controller is a stash, controlled by {{bondedAddress}}',
          multipleStashes:
            'A controller account should not be set to manage multiple stashes. The selected controller is already controlling {{usedStashAddress}}',
          sufficientFunds:
            'The controller does not have sufficient funds available to cover transaction fees. Ensure that a funded controller is used.',
          distinctAccounts:
            'Distinct stash and controller accounts are recommended to ensure fund security. You will be allowed to make the transaction, but take care to not tie up all funds, only use a portion of the available funds during this period.',
          minAmount: 'The bonded amount is less than the minimum bond amount of {{minAmount}}',
          destinationNotExisting:
            'The selected destination account does not exist and cannot be used to receive rewards'
        },
        stakeAmount: {
          notEnough:
            'The specified value is large and may not allow enough funds to pay future transaction fees.',
          minExistentialDeposit:
            'The bonded amount is less than the minimum bond amount of {{existentialDeposit}}',
          minNominatorBond:
            'The bonded amount is less than the minimum threshold of {{minNominatorBond}} for nominators',
          minNominatedAmount:
            'The bonded amount is less than the current active minimum nominated amount of {{minNomination}} and depending on the network state, may not be selected to participate',
          minThresholdValidators:
            'The bonded amount is less than the minimum threshold of {{minBond}} for validators'
        }
      },
      validators: {
        searchInputPlaceholder: 'Search for...',
        withIdentity: 'With identity',
        legendBookmark: 'Favorite',
        legendSelect: 'Select for Staking',
        legendInfo: 'Info',
        legendFlyTo: 'Fly to',
        columnHeaderOperator: 'Operator',
        columnHeaderValidator: 'Validator',
        columnHeaderCommission: 'Commission',
        columnHeaderOwnStake: 'Own Stake'
      }
    },
    wrongBrowser: {
      title: "You're using an unsupported browser. Browsers that are currently supported:",
      browserList: 'Google Chrome, Mozilla Firefox.'
    },
    login: {
      welcome: 'Welcome to',
      enableStaking: 'Login with a polkadot.js account to enable on chain activities',
      connectAsMember: 'Connect as member',
      howToConnectAsAMemberTitle: 'How to connect as a member',
      howToConnectAsAMemberDescription: '',
      installWalletOrConnect: 'Install wallet or connect',
      installBrowserExtension: 'Install browser extension',
      connectWallet: 'Connect  wallet',
      connectYourWallet: 'Connect your wallet',
      connectWith: 'Connect with {{wallet}}',
      createYourProfileTitle: 'Create your profile',
      createYourProfileDescription: '',
      nameInputLabel: 'Enter a name*',
      nameInputPlaceholder: 'Enter a name',
      chooseAnImageLabel: 'Choose an Image',
      areYouReadyText: 'Are you ready? Start counting. Go!',
      startYourJourney: 'Start your journey',
      welcomeUser: 'Welcome {{name}}',
      permissionInfo: 'View only permissions, we will never do anything without your approval',
      auditInfo: 'Audited Smart contracts',
      trustInfo: 'Trusted by {{count}} customers'
    },
    errors: {
      wrongAccount: 'This account cannot be used',
      switchToArbitrum: 'Please switch to Arbitrum Nova network in the wallet',
      switchAccount: 'Please switch to selected account in the wallet',
      oidcSession: 'Something wrong with session',
      addImageBeforeContribution: 'You can add your contribution after adding an image',
      sessionExpired: 'The login session has expired. Please try again.',
      unknownError: 'Could not login please try again.',
      noAccounts:
        'No account detected in the Polkadot.js extension, please create an account to continue here.',
      unsupportedNetwork: "You're connected to an unsupported network.",
      ethereumAccess: 'Please authorize this website to access your Ethereum account.',
      ethereumExtension:
        'No {{name}} extension detected, please install {{name}} to continue here.',
      couldNotRemoveEvent: 'Could not remove the event.',
      endDateGraterThanStartDate: 'End date has to be grater than start date',
      nameConstraints: 'Please enter a name with 2-32 characters',
      somethingWentWrong: 'Something went wrong',
      permissionsMissing: 'Missing permissions',
      permissionsWrong: "Oops, looks like you don't have permissions for this action!",
      somethingWentWrongTryAgain: 'Something went wrong. Please try again later.',
      noWorldBuilderPermissions:
        'Your Account is not whitelisted;\nPlease contact support to request a whitelist slot or login to a different whitelisted account',
      failedToLoadDynamicScript: 'Failed to load dynamic script: {{url}}',
      noModuleSpecified: 'No module specyfied',
      errorWhileLoadingPlugin: 'An error has occured while loading plugin',
      errorPluginContactDev: 'An error has occured in the plugin. Contact the developer.',
      onlyGLBSupported: 'Only GLB format supported',
      requiredField: 'This field is required',
      errorLoadingToken: 'Error loading {{tokenSymbol}}',
      errorLoadingNFT: 'Error loading NFT'
    },
    systemMessages: {
      loadedInAnotherTab: 'Odyssey has been loaded in another browser tab',
      switchToThatTab:
        'To continue the experience, please switch to that window/tab and close this one',
      underMaintenance: 'The server is undergoing maintenance, please try again later'
    },
    counts: {
      people_one: '{{count}} Person',
      people_other: '{{count}} People',
      attendees_one: `{{count}} Attendee`,
      attendees_other: `{{count}} Attendees`,
      vibes_one: '{{count}}  vibe',
      vibes_other: '{{count}}  vibes',
      skyboxes_one: '{{count}} Skybox',
      skyboxes_other: '{{count}} Skyboxes'
    },
    labels: {
      addPicture: 'Add a picture',
      addVideo: 'Add a video',
      addText: 'Add a text',
      home: 'Home',
      by: 'By',
      or: 'Or',
      contribute: 'Contribute',
      contributionOverview: 'Contribution overview',
      version: 'Version {{version}}',
      canvasEditor: 'Canvas editor',
      canvasStep1: 'Read canvas explainer',
      canvasStep2: 'Describe your mission',
      canvasStep3: 'Make a contribution entry',
      canvasStep4: 'AI Image settings',
      canvasStep5: 'Create script AI teamwork',
      canvasStep6: 'Overview and submit',
      aiCredits: '{{amount}} AI credits',
      maxAICredits: 'Max {{count}} AI credits per contribution',
      noAITools: 'No AI Tools active',
      skipAI: 'Skip creating collective intelligence with AI',
      noAIAvailable: 'Restrict users to upload their own image. No AI available',
      screenShare: 'Screenshare',
      stakingOverview: 'Staking Overview',
      calendar: 'World Calendar',
      odysseys: 'Odysseys',
      accounts: 'Accounts',
      account: 'Account',
      price: 'Price',
      myWallet: 'My Wallet',
      token: 'Token',
      members: 'Members',
      emoji: 'EMOJI',
      staked: 'Staked',
      mostStaked: 'Most Staked',
      unbonding: 'Unbonding',
      reward: 'Reward',
      rewards: 'Rewards',
      balance: 'Balance',
      accountBalance: 'Account Balance',
      transferable: 'Transferable',
      totalRewards: 'Total Rewards',
      activeStakes: 'Active Stakes',
      overview: 'Overview',
      lastStakingComment: 'Last Staking Comment',
      totalAmountStaked: 'Total Amount Staked',
      topConnector: 'Top Connector',
      getYourOdyssey: 'Get your Odyssey',
      getYourOdysseyTitle: 'How to buy an Odyssey NFT',
      getYourOdysseyDescription:
        'You need to sign in with your wallet to buy an Odyssey NFT. If you already have one, you will see a pop-up asking for the address you would like to use or create. If you do not have a wallet, please install a browser plugins and refresh the page',
      odysseyMintedTitle: 'Congratulations!',
      odysseyMintedDescription:
        'Welcome to our community. You are now the proud owner of an Odyssey.\nStart building your 3D environment and invite people to join you on your journey.',
      odysseyMintedDescription2:
        'Visit your Odyssey and start with creating a profile with an image, a short description and your mission. This way other people can search and find your Odyssey and start contributing',
      waitDontClose: 'Please wait, don’t close this browser window',
      yourWallet: 'Your Wallet',
      skyboxName: 'Skybox Name',
      staking: 'Staking',
      minimap: 'Minimap',
      musicPlayer: 'Music Player',
      shareLink: 'Share link',
      shareLinkOfOdyssey: 'Share link of Odyssey',
      information: 'Information',
      help: 'Help',
      settings: 'Settings',
      info: 'Info',
      fullscreen: 'Fullscreen',
      embedPicture: 'EMBED A PICTURE',
      embedPictureInfo:
        'by embedding a picture on this object; users can see this image wrapped around the object if it is a basic shape. users will also be able to see this image when they select the object; regardless of its asset type.',
      someonesBio: "{{name}}'s Bio",
      myBio: 'My Bio',
      myConnections: 'My connections',
      universal: 'Universal',
      logout: 'Logout',
      volume: 'Volume',
      distance: 'Distance',
      worldStats: 'World stats',
      tokenRules: 'Token Rules',
      nameOfInitiative: 'Name of Initiative',
      initiatives: 'Initiatives',
      description: 'Description',
      people: 'People',
      explore: 'Explore',
      person: 'Person',
      online: 'Available',
      dnd: 'Do Not Disturb',
      searchResults: 'Search Results',
      results: 'Results',
      attendeeList: 'Attendee List',
      speakers: 'Speakers',
      audience: 'Audience',
      you: 'You',
      cameraOn: 'Camera on',
      cameraOff: 'Camera off',
      microphoneOn: 'Microphone on',
      microphoneOff: 'Microphone off',
      webcamPreview: 'Webcam Preview',
      audioVideoSettings: 'Audio/Video Settings',
      videoinput: 'Video Input',
      audioinput: 'Audio Input',
      audiooutput: 'Audio Output',
      nameWorld: 'Name World',
      worldURL: 'World URL',
      subdomain: 'Subdomain',
      pilot: 'Pilot',
      flyWithMe: 'Fly with me',
      search: 'Search',
      voiceChat: 'Voice chat',
      visitors: 'Visitors',
      chat: 'Chat',
      voice: 'Voice',
      notifications: 'Notifications',
      micOn: 'Mic On',
      micOff: 'Mic Off',
      inspector: 'Inspector',
      gizmo: 'Gizmo',
      assetCustomising: 'Asset Customising',
      selectFunction: 'Assign Function',
      visibleInNavigation: 'Visible in Navigation',
      basicAssetPack: 'Basic Asset Pack',
      communityObjectLibrary: 'Community Library',
      privateObjectLibrary: 'Private Library',
      standardAssetPack: 'Standard Asset Pack',
      wrappableAssetPack: 'Wrappable Asset Pack',
      communityAssetPack: 'Community Assets',
      customObjectLibrary: 'Custom Object Library',
      uploadCustomObject: 'Upload Custom Object',
      sceneExplorer: 'Scene Explorer',
      changeVideo: 'Change Video',
      video: 'Video',
      changeText: 'Change Text',
      document: 'Document',
      changeImage: 'Change Image',
      image: 'Image',
      coCreate: 'co-create',
      connected: 'connected',
      connect: 'connect',
      highFive: 'high five',
      visit: 'visit',
      flyToMe: 'Fly to me',
      bio: 'Bio',
      sound: 'Sound',
      soundtrack: 'Soundtrack',
      soundtrackPlayer: 'Soundtrack player',
      discoverMore: 'Discover more',
      connections: 'Connections',
      newsfeed: 'Newsfeed',
      changeMusic: 'Change Music (coming soon)',
      tokenGating: 'Token Gating (coming soon)',
      addObject: 'Add Object',
      coCreators: 'Co-creators',
      spawnPoint: 'Spawn Point',
      skyboxes: 'Skyboxes',
      addCustomSkybox: 'Add Custom Skybox',
      removeCustomSkybox: 'Remove Skybox',
      here: 'here',
      odysseysOwned: 'Odyssey’s Owned',
      odysseysStakedIn: 'Odyssey’s Staked In',
      mostStakedIn: 'Most Staked In',
      stakedIn: 'Staked In',
      newOdysseys: 'New Odysseys',
      bigStakers: 'Big stakers',
      stakers: 'Stakers',
      newMembers: 'New Members',
      createProfile: 'Create a profile',
      memberProfile: 'Member profile',
      odysseyOverview: 'Odyssey Overview',
      timeline: 'Timeline',
      connectYourWallet: 'Connect your wallet',
      welcomePageJoinTitle: 'Join Odyssey\nas a\nmember',
      welcomePageGuestTitle: 'Enter\nOdyssey as a\nguest',
      welcomePageBuyNftTitle: 'Create your own\nOdyssey',
      welcomePageJoinDescription: 'Connect your wallet\nand join the\ncommunity',
      welcomePageGuestDescription: 'Fly around freely and\nenjoy all the\ncreations',
      welcomePageBuyNftDescription: 'Buy a 3D environment NFT\nand start creating',
      assignFunctionToObjectTitle: 'Assign a function to the object',
      assignFunctionToObjectDescription:
        'By embedding a picture, text, video or sound you change the function of this object; users can see an image wrapped around the object or hear a sound when they fly towards the object. Users will also be able to see the text, image or video displayed when they select the object; regardless of its asset type.',
      commentsCount_one: `{{count}} Comment`,
      commentsCount_other: `{{count}} Comments`,
      contributionCount_one: `{{count}} Contribution`,
      contributionCount_other: `{{count}} Contributions`,
      readMore: 'Read more',
      readLess: 'Read less'
    },
    messages: {
      loading: 'Loading Odyssey',
      loadingPlugin: 'Loading Plugin...',
      loadingToken: 'Loading {{tokenSymbol}}...',
      loadingDynamicScript: 'Loading dynamic script: {{url}}',
      createSnapshotOrVideo:
        'Create a snapshot or a video and share this to all visitors of Odyssey',
      unStakedSuccess:
        'Your transaction has been processed. You have successfully unstaked from {{name}}!',
      unStakedError:
        'Your transaction has not been processed. You unable to successfully unstake from {{name}}!',
      signUp:
        'You need a wallet to create your own Odyssey. If you already have one, you will see a pop-up asking for the address you would like to use or create. If you do not have a wallet, please install a browser plugins and refresh the page',
      orSignIn: 'Or if you already have an account please sign in',
      howSignUp: 'How to create an Odyssey',
      startCounting: 'Are you ready? Start counting. Go!',
      startCreating: 'Start creating your personal journey and...',
      hello_nickname: 'Well done, {{nickname}}! You just created your Odyssey',
      onlineArena: 'Online Mass Collaboration Arena',
      takeSnapshot: 'Take a snapshot from your point of view',
      takeShot: 'Take the shot!',
      startRecording: 'Start recording and fly around',
      recording: 'Recording',
      wasCreatedBy: 'was created by',
      connectWalletJoin: 'Connect your wallet and join the community',
      flyAroundCreations: 'Fly around freely and enjoy all the creations',
      lookForSpace: 'Look for the space of your interest',
      openNewsfeed: 'Open the newsfeed and see everything happening',
      learnAboutObjectives: 'Learn about the objectives and collective results',
      openTimeline: 'Open the timeline and see what’s going on',
      makeMark: 'Make a mark and participate in the mission',
      flyForSpawning:
        'You can fly around with the preview of the object until you reached the perfect spawning point and place the object',
      receivedBoost: 'received a boost of {{amount}} {{symbol}} from {{name}}',
      shareThisPost: 'Share this post',
      loading30sec:
        'Loading an Odyssey could take up to 1-2 minutes, depending on your internet connection. While you wait, you can',
      joinUsOnDiscord: 'join us on Discord',
      clearCache: 'Clear your cache',
      version: 'Odyssey version {{version}}',
      spawnPointUpdated: 'Spawning point was updated.',
      spawnPointNotUpdated: 'Spawning point was not updated.',
      welcome: "Login successful. We invite you to Odyssey's intro. Hit play below!",
      loginSuccessful: 'Login Successful',
      playFriggin: 'Play the Friggin’ Intro',
      noResultsFound: 'No results found',
      worldBuilderSignIn: 'Sign In to your Odyssey Account',
      linkCopied: 'The link is copied to your clipboard.',
      fullyEnjoy: 'Connect your wallet to fully enjoy Odyssey!',
      flyAround: 'Fly around and start experiencing Odyssey!',
      buyNFT: 'Buy a 3D environment NFT and start creating',
      extensionOpen:
        'The {{name}} extension will now open and ask you to authenticate and sign a message.',
      extensionWindow:
        'If this window is not opened then click on the extension in the browser to authenticate and sign.',
      tokenRuleSuccess: 'Token rule was {{action}} successfully',
      tokenRuleFailure: 'An error did occur while {{action}} token rule',
      requestFailure: 'An error has occurred while {{action}}.',
      pleaseConfirm: 'Please confirm',
      delete: 'Are you sure you want to delete this?',
      deleteNamedObject: 'Are you sure you want to delete "{{name}}"?',
      objectDeleted: 'Object removed',
      errorDeletingObject: 'Error removing object',
      deleted: 'Deleted',
      errorDeleting: 'Error deleting',
      saved: 'Saved',
      errorSaving: 'Error saving',
      removeEventSuccess: 'Removed event successfully.',
      sceneExplorerDescription:
        'Below is a list of all the 3d assets currently present in your odyssey. From here you can quickly fly to, edit or delete an object.',
      avatarSuccess: 'Avatar saved successfully',
      avatarFailure: 'There was a problem saving the avatar',
      askToUnstake:
        'You are about to unstake all your {{tokenSymbol}} from this odyssey, it is currently not possible to unstake portions of your staked {{tokenSymbol}}. Are you sure you want to do this?',
      highFiveSentTitle: 'High five sent to {{name}}',
      highFiveSentText: 'You sent a high five',
      highFiveReceivedTitle: '{{name}} has sent you a high five',
      highFiveReceivedText: 'Click on the button to high five back!',
      launchInitiativeNote: 'Feel free to launch your initiative at a later time.',
      allowCustomize: 'Allow visitors to contribute to this object with text, an image or sound',
      maximumParticipants:
        "The maximum number of video participants have been reached. New participants won't be able to stream their webcam.",
      memberAddSuccess: 'The member was successfully added',
      invitationSendSuccess: 'The invitation was sent successfully',
      memberRoleEditSuccess: 'The member role was successfully edited',
      memberRemoveSuccess: 'The member was removed successfully',
      memberRemoveFailure: 'There was an error removing the member',
      userRequestDeny: 'Cannot Accept User Request',
      followingSoundFiles: 'You can upload the following sound files',
      soundExtensions: 'MP3, FLAC, OGG, ACC and WEBM',
      joinGathering: 'The gathering {{title}} is about to start',
      noScreenSharing: 'There is no one screensharing',
      preparingScreenSharing: 'preparing screen share',
      tileCreateSuccess: 'Your tile has been created successfully',
      tileCreateError: 'There was a problem creating your tile',
      tileUpdateSuccess: 'Your tile has been updated successfully',
      tileUpdateError: 'There was a problem updating your tile',
      tileRemoveSuccess: 'Your tile has been successfully removed',
      tileRemoveError: 'There was a problem removing your tile',
      waitForModeratorsToAccept: 'Wait for the moderators to accept or deny your request',
      newDeviceDetected:
        'Odyssey has detected a new {{deviceKind}} device named "{{deviceName}}". Do you want to switch to it?',
      pleaseTryAgainLater: 'Please try again later',
      videoLimitReached: 'Video limit reached',
      worldBuilderDescription:
        'This is the Odyssey creator - a place where you can create your own decentralised social metaverse. Take ownership of your own world to build and create together. Shape your metaverse to suit your needs, enable the functionality you want to use and invite the people you want to collaborate with.',
      startBuilding: 'Connect your wallet\n To start Building',
      flyWithMeEnabled: 'Fly with me enabled',
      flyWithMeActivated: 'Fly with me has been activated',
      flyWithMeDisabled: 'Fly with me has been disabled',
      flyWithMeBlocked: 'During fly with me mode, some functionality has been disabled',
      flyWithMeEnjoy: 'Sit back, relax and enjoy the ride.',
      deletePluginConfirmation: 'Are you sure you want to remove {{pluginName}}?',
      pluginRemovedSuccessfully: '{{pluginName}} removed succesfully!',
      errorWhileRemovingPlugin:
        'Error has occured when removing {{pluginName}} removed succesfully!',
      weSupportGLBModels: 'We support GLB Models',
      glbModelsMaxSize: '(max file size {{size}}MB)',
      selectOne: 'Select One',
      processing: 'Processing',
      setSpawnPoint: 'Set your current location and orientation as the spawn point.',
      comingSoonExclamation: 'Coming Soon!',
      errorBuyingNft: 'Error buying NFT. Please contact the support!',
      mintingYourOdyssey: 'Minting your Odyssey...',
      mintingYourOdysseyWarning: "Please wait and don't refresh, this may take a while.",
      worldNotCreated: 'Sorry, this Odyssey is not created yet.',
      createProfileDesc:
        'Get discovered by providing a name and picture for your Odyssey. The picture will make the outside of your Odyssey shine. The name you provided will be shown for others to search and connect to your Odyssey.',
      yourName: 'What should we call you?',
      chooseImage: 'Choose an image for your odyssey',
      assetUploaded:
        'Congratulations, your custom asset has been added to the Custom Object Library.',
      assetNotUploaded: "Oops, something went wrong your object can't be added to the library.",
      onceConnectedTokensWillBeAwarded:
        'Once connected, you will be awarded some MOM tokens to get you started',
      noWalletFollowInstructions: 'No wallet? You can get one following the instructions',
      removeSkyboxConfirmation: 'You are about to remove the custom skybox',
      removeSkyboxCurrentConfirmation:
        'This Skybox is your Current skybox; removing this will change your skybox to the default one.',
      removeSkyboxFinalConfirmation: 'Are you sure you want to do this?',
      noSoundSelected: 'No sound selected',
      odysseySoundtrack:
        'Here you can set the soundtrack for your Odyssey. The song you select will be heard by visitors to your odyssey when they enter your world. Please be careful only to upload songs you have the rights to. Your Soundtrack will play on loop.',
      objectSound:
        'Users will be able to hear this sound when they fly towards the object. You can upload the following sound files: MP3, OGG, ACC and WEBM',
      uploadAssetInfoTitle: 'Drag and drop your asset here',
      uploadAssetPictureDescription: 'Drag and drop YOUR CUSTOM image here',
      uploadAssetDesc1: 'We support GLB Models.',
      uploadAssetDesc2: 'The max file size cannot exceed 50mb.',
      createCustomSkyboxTitle: 'Create Custom Skybox With AI',
      createCustomSkyboxDescription:
        'Create your own unique 360 skybox for your world by entering a prompt and generating it using the Blockade Labs AI. Simply input your desired concept or atmosphere below as a prompt, and let the AI create a stunning skybox to enhance your Odyssey experience.',
      createCustomSkyboxPlaceholder: 'Enter prompt for AI Skybox here...',
      createCustomSkybixSelectArtStyle: 'Select Art Style',
      createCustomSkyboxGenerateSkybox: 'Generate Skybox',
      createCustomSkyboxPleaseWait: 'Skybox is Generating, Please Wait',
      uploadCustomSkyboxInfoTitle: 'Drag and drop your custom skybox here',
      uploadCustomSkyboxInfoDescription:
        'Your image must be a 360-degree image. This will allow your skybox to fully surround and immerse you in your world. To avoid any pixelation or distortion, we recommend that your image is high-resolution. Our preferred resolution for images is 4k x 2k, but 2k x 1k is acceptable.',
      uploadCustomSkyboxInfoLine1:
        'To ensure that your skybox looks its best, we have a few specifications to keep in mind when uploading your image.',
      uploadCustomSkyboxInfoLine2:
        'Your image must be a 360-degree image. This will allow your skybox to fully surround and immerse you in your world. To avoid any pixelation or distortion, we recommend that your image is high-resolution. Our preferred resolution for images is 4k x 2k, but 2k x 1k is acceptable.'
    },
    titles: {
      alert: 'alert',
      joinAsMember: 'Join Odyssey as a member',
      enterAsGuest: 'Enter Odyssey as a guest',
      searchOdysseysMembers: 'Search Odysseys and members',
      checkLatestNews: 'Check the latest news in Odyssey',
      aboutThisOdyssey: 'About this Odyssey',
      seeUpdates: 'See the latest updates',
      joinAction: 'Join the action',
      createOdyssey: 'Create your own Odyssey',
      myWalletAccounts: 'My wallet accounts',
      connectAnotherWallet: 'Connect another wallet',
      returnHighFive: 'Return the favour',
      describeGoal: 'Describe your mission',
      missionTitle: 'Title of the mission',
      whatIsMission: 'What’s the mission?',
      makeContributionEntry: 'Make a contribution entry',
      createScriptForImage: 'Create a script for AI Image',
      setContributionsAmount: 'Set Max amount of contributions',
      addImageToIdea: 'Add an image to your idea',
      submitContribution: 'Submit your Contribution',
      overviewAITools: 'Overview of AI Tools',
      createTeamworkScript: 'Create the teamwork script',
      canvasQuestion1: 'First question, always ask for a title',
      placeCentralObject: 'Place the central 3D object of the Canvas in Odyssey',
      enterScript: 'Enter your script',
      answerQuestionsCounted: 'Please answer the following {{count}} questions',
      question: 'Question',
      questions: 'Questions',
      later: 'later',
      create: 'create',
      by: 'By',
      objectInfo: 'Object info',
      customizeObject: 'Customize object',
      odysseySoundtrack: 'Odyssey soundtrack',
      objectSound: 'Add sound to object',
      wrapImage: 'Wrap image around object',
      addImage: 'Add image to object info',
      addVideo: 'Add video to object info',
      descriptionOfObject: 'Description of object',
      nameOfObject: 'Name of object',
      creatorEnabled: 'Odyssey Creator Mode Enabled',
      newDeviceDetected: 'New Device Detected',
      inviteUsers: 'Invite users',
      yourRequestHasBeenAccepted: 'Your request has been accepted,',
      getReadyToGetLive: 'Get ready. You are about to go live in.',
      yourRequestWasDeclined: 'Your request was declined',
      broadcastManage: 'Manage Broadcast',
      profile: 'Profile',
      myProfile: 'My profile',
      broadcast: 'Broadcast',
      name: 'Name',
      template: 'Template',
      generate: 'Generate',
      worldBuilder: 'Odyssey Creator',
      worldChat: 'World Chat',
      templateNameWrapper: '{{name}} Template',
      success: 'Success',
      failedToUpload: 'Failed to upload',
      plugins: 'Plugins',
      addPlugin: 'Add Plugin',
      error: 'Error',
      social: 'Social',
      selectFunctionality: 'Select Functionality',
      spawnAsset: 'Spawn Asset',
      selectedSkybox: 'Selected Skybox',
      upload3dAsset: 'Upload 3D Asset',
      admin: 'Admin',
      owner: 'Owner',
      colourPicker: 'Change colour of object',
      setSpawnPoint: 'Set Spawn Point',
      statistics: 'Statistics',
      communitySkyboxLibrary: 'Community Library',
      privateSkyboxLibrary: 'Private Library',
      odysseySkyboxLibraryHeader: 'Odyssey Library',
      communitySkyboxLibraryHeader: 'Community',
      privateSkyboxLibraryHeader: 'Private Library',
      whatIsCanvas: 'What is a user canvas',
      howToBuildCanvas: 'How to Build a canvas in {{count}} steps',
      synthesis3d: 'Synthesis in 3D',
      usingAiTeamwork: 'Using AI to create Teamwork',
      shareContribution: 'Share your contribution',
      becomeMemberToContribute: 'Become member before you Contribute',
      titleMissionOfOdyssey: 'Title mission of this Odyssey',
      contributionMatters: 'Your contribution matters, literally',
      contributeSteps: 'Contribute in 3 steps',
      answerQuestions: 'Answer a couple of questions',
      addImageToContribution: 'Add an image to your contribution',
      checkSubmit: 'Check and submit',
      scale: 'Scale',
      rotation: 'Rotation',
      position: 'Position'
    },
    tooltipTitles: {
      openAdmin: 'Open Admin',
      closeChat: 'Close Chat',
      openChat: 'Open Chat',
      favorite: 'Favorite',
      flyAround: 'Fly Around',
      close: 'Close'
    },
    placeholders: {
      search: 'Search',
      sort: 'Sort',
      addComment: 'Add a comment',
      searchForPeople: 'Search for people...',
      searchForAssets: 'Search for assets...',
      searchForAttendees: 'Search for attendees...',
      nameYourWorld: 'Name your world',
      nameYourImage: 'Name your image',
      selectModel: 'Select a model',
      contributions: 'Contributions',
      worldname: 'worldname',
      selectPlugin: 'Select Plugin',
      shortTitleLimited: 'A short title, maximum 80 characters',
      yourInput: 'Your input',
      nameYourAssetForYourLibrary: 'Name your Asset for your library',
      whatIsProduct: 'What is the product you want to create collectively',
      nameYourAsset: 'Name your Asset*',
      nameTheArtist: 'Name the Artist',
      selectAnOption: 'Select an Option',
      nameYourObjectNavigation: 'Name your Object (Navigation)',
      missionTitle: 'Title, maximum 80 characters.',
      missionStory:
        'Describe your mission, your goal with this Odyssey or just a statement. Keep it short and invite visitors to contribute.',
      canvasQuestion1: 'What’s the title of your contribution?',
      canvasQuestion2:
        'Write your second question, ask your visitors what they can contribute. For example an opinion, or an idea.',
      canvasQuestion3:
        'Third question, ask your visitors how they would achieve this idea or solve the problem.',
      canvasQuestion4: 'This question is optional. Keep the survey short for the best result.',
      canvasScript:
        'This is an example of a script:\n\nLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.\n\n#answer1\n#answer2',
      canvasTeamworkScript:
        'This is an example of a script:\n\nLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.',
      selectAccount: 'Select Account',
      searchCommunityLibrary: 'Search Community Library',
      searchPrivateLibrary: 'Search Private Library',
      defaultAssetName: 'Default Asset Name'
    },
    fields: {
      nickname: 'Nickname',
      description: 'Description',
      addDescription: 'Add a description',
      name: 'Name',
      nameOfSound: 'Name of sound',
      profileName: 'Profile name',
      title: 'Title',
      social: 'Social',
      bio: 'Bio',
      shortBio: 'Short bio',
      location: 'Location',
      link: 'Link',
      linkToWeb: 'Link to website'
    },
    networks: {
      polkadot: 'Polkadot.js',
      metamask: 'Metamask',
      walletconnect: 'Wallet connect',
      guest: 'Guest'
    },
    actions: {
      yes: 'Yes',
      no: 'No',
      ok: 'OK',
      add: 'Add',
      signIn: 'Sign In',
      signUpNow: 'Sign up now',
      generateImage: 'Generate image',
      startSearching: 'Start searching',
      openNewsfeed: 'Open the newsfeed',
      checkCore: 'Check the core',
      openTimeline: 'Open the timeline',
      flyAround: 'Fly around',
      overview: 'Overview',
      signOut: 'Sign Out',
      sort: 'Sort',
      logOut: 'Log out',
      embed: 'Embed',
      comment: 'Comment',
      next: 'Next',
      becomeMember: 'Become member',
      createAIImage: 'Create an AI image',
      uploadImage: 'Upload an image',
      startContributing: 'Start contributing',
      addComment: 'Add comment',
      manageWallet: 'Manage wallet',
      removeObject: 'Remove object',
      describeMission: 'Describe mission',
      aiImageScript: 'Ai image script',
      scriptTeamwork: 'Script teamwork',
      imageUpload: 'Image upload',
      leonardo: 'Leonardo',
      chatGPT: 'Chat GPT',
      noTeamworkOutcome: 'No teamwork outcome',
      settings: 'Settings',
      publish: 'Publish',
      default: 'Default',
      preview: 'Preview',
      spawn: 'Spawn',
      setAmount: 'Set Amount',
      submitCanvas: 'Submit canvas',
      previewCanvas: 'Preview canvas',
      spawnCanvas: 'Spawn canvas',
      setAsDefault: 'Set as default',
      takeSnapshot: 'Take a snapshot',
      createVideo: 'Create a video',
      createEvent: 'Create an event',
      createEntry: 'Create an entry',
      edit: 'Edit',
      deleteObject: 'Delete object',
      duplicateObject: 'Duplicate object',
      editObjectInfo: 'Edit object info',
      editProfile: 'Edit profile',
      claimRewards: 'Claim Rewards',
      nextStep: 'Next Step',
      requestAirdropTokens: 'Request Airdrop tokens',
      startAirdrop: 'Send tokens',
      selectWallet: 'Select wallet',
      createOdyssey: 'Create your Odyssey',
      installWallet: 'Install your wallet',
      connectWallet: 'Connect your wallet',
      startJourney: 'Start your journey',
      buildOdyssey: 'Build your Odyssey',
      buyNft: 'Buy Odyssey NFT',
      visitYourOdyssey: 'Visit your Odyssey',
      createOrSignIn: 'Create an account or sign in',
      searchContributions: 'Search contributions',
      searchOdysseys: 'Search Odysseys',
      searchMembers: 'Search Members',
      searchStakes: 'Search Stakes',
      visitOdyssey: 'Visit Odyssey',
      buyOdyssey: 'Buy Odyssey',
      share: 'Share',
      stakeInOdyssey: 'Stake in Odyssey',
      addToTimeline: 'Add to timeline',
      seeAll: 'See all',
      approve: 'Approve',
      decline: 'Decline',
      cancel: 'Cancel',
      react: 'React',
      emailLogin: 'Email Login',
      goBack: 'Go back',
      back: 'Back',
      muteAll: 'Mute All',
      rejoinMeeting: 'Rejoin meeting',
      clickHere: 'Click here',
      chooseAvatar: 'Choose an image for your avatar',
      saveProfile: 'Save profile',
      selectAccount: 'Select account',
      selectPolkadotAccount: 'Select your Kusama address to login',
      copyLink: 'Copy Link',
      createInitiative: 'Launch new initiative',
      flyTo: 'Fly to',
      highFive: 'High five',
      connect: 'Connect',
      connected: 'Connected',
      coCreate: 'co-create',
      dock: 'Dock',
      visit: 'Visit',
      creatorOpen: 'Odyssey Creator',
      uploadYourSound: 'Upload your sound',
      creatorClose: 'Close Odyssey Creator',
      saveChanges: 'Save changes',
      approving: 'approving',
      approved: 'approved',
      delete: 'Delete',
      declined: 'declined',
      declining: 'declining',
      removed: 'removed',
      removing: 'removing',
      joined: 'Joined',
      created: 'Created',
      refresh: 'Refresh',
      launch: 'Launch',
      confirmRemove: 'yes, remove',
      removeRule: 'remove rule',
      noCancel: 'no, cancel',
      submit: 'submit',
      save: 'save',
      stake: 'Stake',
      unstake: 'Unstake',
      addStake: 'Add stake',
      info: 'Info',
      invite: 'Invite',
      invited: 'Invited',
      closeAndContinue: 'Close and Continue',
      return: 'Return',
      leave: 'Leave',
      muteName: 'Mute {{name}}',
      kickName: 'Kick {{name}}',
      kickNameFromVoice: 'Kick {{name}} from Voice',
      remove: 'Remove',
      mute: 'Mute',
      unmute: 'Unmute',
      addSound: 'Add a sound',
      startScreenShare: 'Start screensharing',
      accept: 'Accept',
      switchDevice: 'Switch device',
      getReady: 'Get Ready',
      close: 'Close',
      ready: 'Ready',
      uploadSoundFile: 'Upload a sound file',
      logIn: 'Log In',
      getStarted: 'Get Started',
      selectTemplate: 'Select Template',
      generateWorld: 'Generate World',
      disengageReturn: 'Disengage And Return',
      join: 'join',
      dismiss: 'dismiss',
      joinVoiceChat: 'Join the voice channel',
      leaveVoiceChat: 'Leave the voice channel',
      select: 'Select',
      uploadYourAsset: 'Upload your asset',
      addToLibrary: 'Add to library',
      dropItHere: 'Drop it here',
      dragDropSound: 'Drag and drop your custom sound',
      closePanel: 'Close Panel',
      selectSkybox: 'Select Skybox',
      addSkybox: 'Add Skybox',
      removeSkybox: 'Remove Skybox',
      upload: 'Upload',
      spawnObject: 'Spawn Object',
      change: 'Change',
      changeVideo: 'Change Video',
      changeText: 'Change Text',
      changeImage: 'Change Image',
      selectImage: 'Select Image',
      createCustomSkyboxAI: 'Create Custom Skybox With AI',
      uploadCustomSkybox: 'Upload Custom Skybox',
      move: 'Move',
      rotate: 'Rotate',
      scale: 'Scale',
      undo: 'Undo',
      redo: 'Redo',
      copy: 'Copy',
      clearSelection: 'Clear Selection',
      inspector: 'Inspector',
      functionality: 'Functionality',
      colour: 'Colour',
      chooseName: 'Choose your name',
      addTokenGate: 'Add Token Gate'
    },
    enums: {
      basicAsset2dId: {
        TEXT: 'Text',
        IMAGE: 'Image',
        VIDEO: 'Video',
        DOCK: 'Dock station',
        MIRO: 'Miro',
        GOOGLE_DRIVE: 'Google Drive'
      }
    },
    descriptions: {
      worldName: 'This is the name of the metaverse you will be creating',
      worldSubdomain:
        'This is the subdomain of the publicly facing URL that people will visit when then visiting your metaverse',
      worldURL:
        'This is the publicly facing URL that people will visit when then visiting your metaverse',
      canvasStep1_One:
        'Enable visitors to contribute their ideas and objects to your Odyssey by creating an User Canvas.',
      canvasStep1_Two:
        'You can ask visitors to share their ideas and opinions in this Odyssey based on your on your goal and a little survey. The contributions of the visitors will spawn as a 3D object in your world.',
      canvasStep1_Three:
        'By using AI you can compile all the contributions and translate these to a common goal or a masterplan. This is called Teamwork.',
      canvasStep2_One:
        'Write a (short) story about the mission of this Odyssey, what do you want to achieve and what do you expect your visitors to contribute.',
      canvasStep3_One:
        'With this survey you can ask your visitors specific questions about the mission of your Odyssey or invite them to share their ideas. Keep it short and simple, you can ask maximal 4 questions.',
      canvasStep4_One:
        'Visitors can generate an image with AI based on their answers. If you want to use this feature please enter a script to create a specific format or style. Users can also choose to upload their own image.',
      canvasStep5_One:
        'With this feature you can assemble all the contributions and translate these to a common goal or a master plan with the help of AI. You can make a script to create a format, for example a masterplan with all the ideas or a resume.',
      canvasStep6_One:
        'By creating the canvas you spawn a central Canvas object in the Odyssey where the contributions will be placed around.',
      canvasStep6_Two:
        'You can fly with the preview of the object until you reached the perfect spawning point and place the object',
      canvasStep6_Three:
        'You can fly around with the preview of the object until you reached the perfect spawning point and place the object'
    },
    fileUploader: {
      uploadLabel: 'Upload Image',
      changeLabel: 'Change Image',
      dragActiveLabel: 'Drop the files here...'
    },
    assetsUploader: {
      uploadLabel: 'Upload .glb file',
      dragActiveLabel: 'Drop the files here...',
      successMessage: 'Asset successfully uploaded',
      errorUnsupportedFile: 'Only .glb files are supported',
      errorTooLargeFile: 'Max file size is {{size}}MB',
      errorSave: 'Error uploading asset'
    },
    skyboxGenerator: {
      errorGenerating: 'Error generating skybox'
    },
    devices: {
      device: 'device',
      camera: 'camera',
      microphone: 'microphone',
      video: 'video',
      audio: 'audio',
      selectInputSource: 'Select input source',
      selectOutputSource: 'Select output source'
    },
    plugin_gd: {
      labels: {
        googleDrive: 'Google Drive'
      },
      messages: {
        noDocument: 'This team does not have a Google Drive Document open yet',
        noTeamDocument: 'Your team does not have a Google Drive Document open yet'
      },
      actions: {
        chooseDocument: 'Choose a document',
        changeDocument: 'Change document',
        close: 'Close'
      }
    },
    plugin_miro: {
      labels: {
        miro: 'Miro'
      },
      messages: {
        noTeamMiroBoard: 'Your team does not have a Miro board yet',
        noMiroBoard: 'This team does not have a Miro board yet'
      },
      actions: {
        changeBoard: 'Change board',
        closeBoard: 'Close board',
        chooseBoard: 'Choose a Miro board'
      }
    },
    plugin_video: {
      labels: {
        embed: 'Embed a video',
        video: 'Video',
        videoUrl: 'Video URL',
        videoPreview: 'Video Preview:'
      },
      messages: {
        pasteUrl: 'Paste a YouTube, Twitch, or Vimeo Share URL here.',
        name: 'Name your video',
        invalidUrl: 'Invalid or unsupported URL',
        noUrl: 'Video not yet set'
      },
      actions: {
        save: 'Save',
        cancel: 'Cancel',
        change: 'Change'
      }
    }
  }
};
